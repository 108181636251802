import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'
import VueMoment from 'vue-moment'
import VueBus from 'vue-bus';
import VueI18n from 'vue-i18n';
import messages from './i18n';

import Util from './Util';

Vue.config.productionTip = false
Vue.use(VueMoment);
Vue.use(VueBus);
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: Util.lang(),
  messages,
});

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
  i18n,
}).$mount('#app')
