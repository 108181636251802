
export default {
	namespaced: true,
	state: () => ({
        activeChats:[]
    }),
	mutations: {
		orderChats(state) {
			let copy = [...state.activeChats];
			let sorted  = copy.sort((a,b)=>{	
				let timeA = new Date(a.lastUpdated.toDate()).getTime();
				let timeB = new Date(b.lastUpdated.toDate()).getTime();
				if(timeA < timeB){
					return 1
				}else{
					return -1
				}
			})
			state.activeChats = [...sorted];
			
		}
	},
	actions: {},
	modules: {},
};