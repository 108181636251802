export default {
    namespaced: true,
	state: () => ({
		access_token: null,
		user: null,
	}),
	getters: {
		isLogin(state) {
			return state.access_token !== null;
		}
	},
	mutations: {
		setUserToken(state, access_token) {
			state.access_token = access_token;
		},
		setUserData(state, user) {
			state.user = user;
		},
		setUnitTransport(state, unit) {
			state.user.currentTransportUnit = unit;
		},
        logout (state) {
            state.access_token = null;
            state.user = null;
        },
		setLang(state, lang) {
			state.user.lang = lang;
		}
	},
	actions: {
        logout ({ commit }) {
            commit('logout');
        }
    },
	modules: {},
};
