export default {
	selectUnit: {
		txtTitle: 'Selecciona la unidad que manejas',
		txtNotification: 'Unidad asignada con exito'
	},
  global: {
    txtDelete: "Eliminar",
    /* email: "Correo o numero de telefono", */
    email: "Correo",
    emailRecover: "Correo",
    password: "Contraseña",
    rules: {
      passwordLength: "Mínimo 8 caracteres",
      passwordRequired: "Se requiere contraseña",
      emailRequired: "Requerido",
      emailValid: "E-mail no es valida",
      passwordNoMatch: "Las contraseñas no coinciden",
    },
    snackbar: {
      close: "Cerrar",
    },
    navigation: {
      txtServices: "Servicios",
      txtHistory: "Histórico",
      txtChecking: "Check In",
    },
    dialog: {
      btnCancel: "Cancelar",
      btnToAccept: "Aceptar",
      btnUpdate: "Actualizar",
    },
    components: {
      services: {
        txtCalendar: "Servicios del",
      },
      qrReader: {
        txtTitle: "Check in",
        txtInfo:
          "Favor de centrar el codigo QR para aplicar el Check In del pasajero.",
        txtServiceNoAsign:
          "El servicio que esta buscando no existe o no se le ha asignado",
        txtErrorGeneric:
          "Ocurrio un error al realizar el checkin del servicio.",
        txtInfoSelect:
          "Seleccione el tipo de checkin, de preferencia el uso de QR.",
        txtOptions: "Opciones",
        txtTitleCheckinM: "Checkin Manual",
        txtDescM:
          "Se aplicara un checkin manual, tome en cuenta que se monitorea en todo tiempo.",
      },
      listServices: {
        txtEmptyServices: "Sin servicios",
        txtSearch: "ID del servicio",
      },
      empty: {
        txtEmptyData: "No existen datos",
      },
      history: {
        txtCalendar: "Historial del",
      },
      noshow: {
        txtPhoto1:
          "Tómate una foto con el letrero del nombre del cliente, en el punto de encuentro de fondo.",
        txtPhoto2:
          "Toma una foto del vehículo con el fondo del lugar de pickup.",
        txtPhoto3:
          "Toma una foto de un formato impreso que atestigüe el No show (formato de no show, ticket de estacionamiento, pase de salida del pax).",
      },
      offline: {
        txtTitle: "Offline",
        txtOffline: "Sin conexión",
      },
    },
  },
  login: {
    forgotPassword: "Olvide mi contraseña",
    btnLogin: "Iniciar",
    btnInstallApp: "Instalar App",
    txtErrorRetrievingUserData: "Error al recuperar los datos del usuario",
    txtEmailPasswordInvalid: "Correo/Contraseña invalidos",
  },
  recover: {
    updateTitle: "Cambiar Contraseña",
    newPassword: "Nueva contraseña",
    repeatPassword: "Repetir Contraseña",
    btnUpdatePassword: "Actualizar Contraseña",
    txtErrorUpdate: "Ocurrio un problema al actualizar el password.",
    txtSuccessUpdate: "Contraseña actualizada con éxito"
  },
  listChat: {
    today: "Hoy",
    noMessages: "Sin Mensajes",
  },
  home: {
    profile: "Perfil",
    logout: "Salir",
    waiting: "Espere un momento",
    txtHelpSupport: "Servicio de asistencia",
    txtButtomUpdate: "Buscar actualización",
    txtSelectUnit: 'Seleccionar Unidad',
		txtLicense: 'Licencia',
		txtUnit: 'Unidad',
		txtPlates: 'Placas'
  },
  forgot: {
    txtTitle: "Recuperar contraseña",
    btnReturnToLogin: "Volver a Login",
    btnSendEmail: "Enviar Email",
    txtEmaildSend:
      "Se envió un correo electrónico de recuperación de contraseña.",
    txTunexpectedError: "Ocurrió un error inesperado",
  },
  chat: {
    newMessage: "Nuevo Mensaje",
    txtNoLoggetForChat: "No registrado para chatear",
  },
  booking: {
    pickupOn: "Recoger en",
    at: "a",
    dropoffAt: "Dejar en",
    flightNumber: "Número de vuelo",
    fligthTime: "Tiempo de vuelo",
    notes: "Notas",
    txtErrorServiceInProcess: "Ya cuenta con otro servicio en proceso",
    txtErrorNoMatchQr: "El servicio actual no coincide con el QR.",
    txtErrorNoShow: "Notificara que no encontro al cliente.",
    txtError60Min: "Aun no pasan 60 minutos",
    txtError15Min: "Aun no pasan 15 minutos",
    txtErrorNoTrip: "No se reconoce el tipo del Viaje",
    txtNoShowNotAllowed: "No Show no permitido",
    txtNoShowPlus: "para indicar un no show.",
    txtErrorChangeStatus: "Ocurrio un problema al cambiar el estado",
    txtChangeStatus: "Cambio de estado",
    dialog: {
      btnCancel: "Cancelar",
      btnToAccept: "Aceptar",
    },
    bookingStatusOpts: {
      txtActive: "Activo",
      txtNoShow: "No Show",
      txtNoProvided: "No atendido",
      txtCanceled: "Cancelado",
      txtDone: "Realizado",
    },
    steps: {
      pending: {
        btnText: "",
        txtLabel: "Pendiente",
        txtChangeStatusAlert: "El servicio esta como pendiente.",
      },
      onWayToPickup: {
        btnText: "Iniciar viaje al Origen",
        txtLabel: "Rumbo a $pickup",
        txtChangeStatusAlert: "Iniciara viaje para llegar a $pickup",
      },
      atPickup: {
        btnText: "He llegado al Origen",
        txtLabel: "En Origen para Pickup",
        txtChangeStatusAlert: "Avisara que ha llegado a $pickup",
      },
      onWayToDropoff: {
        btnText: "Iniciar viaje a Destino",
        txtLabel: "Rumbo a Destino",
        txtChangeStatusAlert: "Iniciara viaje para llegar a $dropoff",
      },
      finished: {
        btnText: "Fin del servicio",
        txtLabel: "Servicio finalizado",
        txtChangeStatusAlert: "Avisara que ha realizado el servicio con éxito",
      },
    },
    txtErrorCord: "Las coordenadas del servicio no son correctas.",
    txtErrorDistance: "Aun no se encuentra dentro del radio de un 5 KM.",
    txtGpsError: "GPS desactivo, favor de activarlo.",
  },
  profile: {
    txtFirstName: "Primer Nombre",
    txtLastName: "Apellido",
    txtEmail: "Correo",
    txtLicenseNumber: "Numero de licencia",
    txtTypeUser: "Tipo de usuario",
    txtLanguaje: "Lenguaje",
    btnSave: "Guardar",
  },
  update: {
    txtTitle: "Actualizando Versión",
    txtUpdate: "Se descargo una nueva versión, favor de recargar la app.",
    btnReload: "Recargar app",
  },
  noShow: {
    txtTitle: "Evidencias de No Show",
    takePhoto: "Tomar foto",
  }
}
