export default {
	namespaced: true,
	state: () => ({
		navigation: {
			active: false,
			itemActive: null,
			IndexItemActive: 0,
			items: [],
		},
		chat: {
			countMsg: 0,
        },
        chatActive: true,
		backActive: false,
		qr: {
			active: false,
			data: null,
			onlyEvent: null,
			select: false
		},
		online: false,
		core: {
			dialogLoading: false,
		},
        snackbar: {
            active: false,
            text: '',
        }
	}),
	mutations: {
		onlineEvent(state, active) {
			state.online = active;
		},
		backBtn(state, active) {
			state.backActive = active;
		},
		navigationActivator(state, stateNav) {
			state.navigation.active = stateNav;
		},
		addNavigationItem(state, item) {
			for (let i in state.navigation.items) {
				if (state.navigation.items[i].name === item.name) {
					return true;
				}
			}
			state.navigation.items.push(item);
		},
		removeNavigationItem(state, name) {
			for (let i in state.navigation.items) {
				if (state.navigation.items[i].name === name) {
					delete state.navigation.items[i];
				}
			}
		},
		clearNavigationItems(state) {
			state.navigation.items = [];
		},
		selectNavigationItem(state, index) {
			state.navigation.IndexItemActive = index;
			state.navigation.itemActive =
				state.navigation.items[index].component;
		},
		chatCountMsg(state, count) {
			state.chat.countMsg = count;
		},
		changeQRData(state, data) {
			state.qr.data = data;
			if (data === null) {
				state.onlyEvent = null;
				state.qr.select = false;
			}
		},
		changeQR(state, st) {
			state.qr.active = st;
		},
        changeQROnly(state) {
            state.qr.active = true;
            state.qr.onlyEvent = true;
			state.qr.select = true;
        },
		changeProgress(state, val) {
			state.core.dialogLoading = val;
		},
        openSnackbar(state, text, active) {
            state.snackbar.active = active === undefined ? true : false;
            state.snackbar.text = text || '';
        },
		closedSnackbar (state) {
            state.snackbar.active = false;
			state.snackbar.text = '';
        },
	},
	actions: {
		disabledNavigation(context) {
			context.commit("navigationActivator", false);
		},
		enableddNavigation(context) {
			context.commit("navigationActivator", true);
		},
		openQRReader(context) {
			context.commit("changeQR", true);
		},
		openQRReaderOnlyEvent(context) {
			context.commit("changeQROnly");
		},
		closedQRReader(context) {
			context.commit("changeQR", false);
		},
		resetQR(context) {
			context.commit("changeQRData", null);
		},
		activeBackBtn(context) {
			context.commit("backBtn", true);
		},
		disabledBackBtn(context) {
			context.commit("backBtn", false);
		},
		showProgress(context) {
			context.commit("changeProgress", true);
		},
		hideProgress(context) {
			context.commit("changeProgress", false);
		},
	},
};
