export default {
	selectUnit: {
		txtTitle: 'Select the unit you drive',
		txtNotification: 'Successfully assigned unit'
	},
	global: {
		txtDelete: 'Delete',
		//email: "Email or phone number",
		email: "Email",
		emailRecover: "Email",
		password: "Password",
		rules: {
			passwordLength: "Minimum 8 characters",
			passwordRequired: "Password is required",
			emailRequired: "Required",
			emailValid: "E-mail must be valid",
			passwordNoMatch: "The password does not match",
		},
		snackbar: {
			close: "Close",
		},
		navigation: {
			txtServices: "Services",
			txtHistory: "History",
			txtChecking: "Check In",
		},
		dialog: {
			btnCancel: "Cancel",
			btnToAccept: "Continue",
			btnUpdate: "Update"
		},
		components: {
			services: {
				txtCalendar: "Services of the",
			},
			qrReader: {
				txtTitle: "Check in",
				txtInfo:
					"Please center the QR code to apply the passenger Check In.",
				txtServiceNoAsign:
					"The service you are looking for does not exist or has not been assigned.",
				txtErrorGeneric:
					"An error occurred when checking in the service.",
				txtInfoSelect: 'Select the type of check-in, preferably using QR.',
				txtOptions: 'Options',
				txtTitleCheckinM: 'Checkin Manual',
				txtDescM: 'A manual checkin will be applied, take into account that it is monitored at all times.'
			},
			listServices: {
				txtEmptyServices: "No services",
				txtSearch: "service ID"
			},
			empty: {
				txtEmptyData: "No data",
			},
			history: {
				txtCalendar: "History of",
			},
			noshow: {
				txtPhoto1: "Take a photo with the client's name sign at the meeting point.",
				txtPhoto2: 'Take a photo of your vehicle at the pickup location.',
				txtPhoto3: 'Take a photo of a printed format to support the No show (no show format, parking ticket, exit pass).'
			},
			offline: {
				txtTitle: 'Offline',
				txtOffline: 'Without connection'
			}
		},
	},
	login: {
		forgotPassword: "Forgot Password",
		btnLogin: "Login",
		btnInstallApp: "Install App",
		txtErrorRetrievingUserData: "Error retrieving user data",
		txtEmailPasswordInvalid: "Email/Password invalid",
	},
	recover: {
		updateTitle: "Change Password",
		newPassword: "New Password",
		repeatPassword: "Repeat Password",
		btnUpdatePassword: "Update Password",
		txtErrorUpdate: 'Ocurrio un problema al actualizar el password.',
		txtSuccessUpdate: "Password updated successfully"
	},
	listChat: {
		today: "Today",
		noMessages: "No Messages",
	},
	home: {
		profile: "Profile",
		logout: "Sign off",
		waiting: "Wait a moment",
		txtHelpSupport: 'Help support',
		txtButtomUpdate: 'Check for update',
		txtSelectUnit: 'Select Unit',
		txtLicense: 'License',
		txtUnit: 'Unit',
		txtPlates: 'Plates'
	},
	forgot: {
		txtTitle: "Recover Password",
		btnReturnToLogin: "Return to Login",
		btnSendEmail: "Send Email",
		txtEmaildSend: "A password recovery email was sent.",
		txTunexpectedError: "An unexpected error occurred",
	},
	chat: {
		newMessage: "New message",
		txtNoLoggetForChat: "no logged for chat",
	},
	booking: {
		pickupOn: "Pickup On",
		at: "at",
		dropoffAt: "Dropoff at",
		flightNumber: "Flight Number",
		fligthTime: "Fligth Time",
		notes: "Notes",
		txtErrorServiceInProcess: "You already have another service in process",
		txtErrorNoMatchQr: "The current service does not match the QR.",
		txtErrorNoShow: "It will notify that it did not find the client.",
		txtError60Min: "60 minutes have not passed yet",
		txtError15Min: "Not yet 15 minutes",
		txtErrorNoTrip: "Trip type is not recognized",
		txtNoShowNotAllowed: "No Show not allowed",
		txtNoShowPlus: "to indicate a no show.",
		txtErrorChangeStatus: "There was a problem when changing the state",
		txtChangeStatus: "Status change",
		dialog: {
			btnCancel: "Cancel",
			btnToAccept: "Continue",
		},
		bookingStatusOpts: {
			txtActive: "Active",
			txtNoShow: "No Show",
			txtNoProvided: "Not attended",
			txtCanceled: "Cancelled",
			txtDone: "Done",
		},
		steps: {
			pending: {
				btnText: "",
				txtLabel: "pending",
				txtChangeStatusAlert: "The service is pending",
			},
			onWayToPickup: {
				btnText: "Start trip to the Origin",
				txtLabel: "Heading to $pickup",
				txtChangeStatusAlert: "Start trip to get to $pickup",
			},
			atPickup: {
				btnText: "I have arrived at the Origin",
				txtLabel: "In Origin for Pickup",
				txtChangeStatusAlert:
					"It will notify you that you have reached $pickup",
			},
			onWayToDropoff: {
				btnText: "Start trip to Destination",
				txtLabel: "Heading to Destination",
				txtChangeStatusAlert: "Start trip to get to $dropoff",
			},
			finished: {
				btnText: "End of service",
				txtLabel: "Service ended",
				txtChangeStatusAlert:
					"It will notify you that you have performed the service successfully",
			},
		},
		txtErrorCord: 'The service coordinates are not correct.',
		txtErrorDistance: 'Not yet within a 5 KM radius.',
		txtGpsError: 'GPS deactivated, please activate it.'
	},
	profile: {
		txtFirstName: "First Name",
		txtLastName: "Last Name",
		txtEmail: "Email",
		txtLicenseNumber: "License Number",
		txtTypeUser: "Type User",
		txtLanguaje: "Languaje",
		btnSave: "Save",
	},
	update: {
		txtTitle: 'Updating Version',
		txtUpdate: 'A new version was downloaded, please reload the app.',
		btnReload: 'Reload app'
	},
	noShow: {
		txtTitle: 'No show evidences',
		takePhoto: 'Take picture',
	}
};
