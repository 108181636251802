import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import(/* webpackChunkName: "Home" */ "../views/Home.vue"),
    children: [
      {
        path: "",
        name: "Home",
        component: () =>
          import(/* webpackChunkName: "Home" */ "../views/Principal.vue"),
      },
      {
        path: "/profile",
        name: "Profile",
        component: () =>
          import(/* webpackChunkName: "Profile" */ "../views/Profile.vue"),
      },
      {
        path: "/select-unit",
        name: "SelectUnit",
        component: () =>
          import(
            /* webpackChunkName: "SelectUnit" */ "../views/SelectUnit.vue"
          ),
      },
      {
        path: "/qr-reader",
        name: "QRReader",
        component: () =>
          import(/* webpackChunkName: "Home" */ "../views/Principal.vue"),
      },
      {
        path: "/booking/:id",
        name: "booking",
        component: () =>
          import(/* webpackChunkName: "Booking" */ "../views/Booking.vue"),
      },
      {
        path: "/no-show/:id",
        name: "noshow",
        component: () =>
          import(/* webpackChunkName: "NoShow" */ "../views/Noshow.vue"),
      },
      {
        path: "/chat",
        name: "ListChat",
        component: () =>
          import(/* webpackChunkName: "LisChat" */ "../views/listChat.vue"),
      },
      {
        path: "/chat/:id",
        name: "Chat",
        component: () =>
          import(/* webpackChunkName: "Chat" */ "../views/Chat.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/update",
    name: "Update",
    component: () =>
      import(/* webpackChunkName: "update" */ "../views/Update.vue"),
  },
  {
    path: "/forgot",
    name: "Forgot",
    component: () =>
      import(/* webpackChunkName: "forgot" */ "../views/Forgot.vue"),
  },
  {
    path: "/update-passwd",
    name: "UpdatePassword",
    component: () =>
      import(/* webpackChunkName: "Recover" */ "../views/Recover.vue"),
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  let currentUser = store.state.auth.user;
  const findName = ["Login", "Forgot", "UpdatePassword", "Update"].indexOf(
    to.name
  );
  if (findName === -1 && currentUser === null) {
    next({ name: "Login" });
  } else if (findName === 0 && currentUser) {
    next({ name: "Home" });
  }
  next();
});

export default router;
