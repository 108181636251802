export default {
	namespaced: true,
	state: () => ({
		data: null,
		id: null,
		gpc: [],
		stateIndex: 0,
		checkin: false,
		calendar: {
			services: null,
			history: null
		}
    }),
	mutations: {
		setService(state, data) {
			state.data = data;
			if (data) {
				state.id = data.id;
			} else {
				state.id = null;
				state.gpc = [];
				state.state = null;
				state.checkin = false;
			}
		},
		addGpcLocation(state, data) {
			state.gpc.push(data);
		},
		changeStatus(state, index) {
			state.stateIndex = index;
		},
		checkin (state) {
			state.checkin = true;
		},
		setDateHistory(state, d) {
			state.calendar.history = d;
		},
		setDateService(state, d) {
			state.calendar.services = d;
		}
	},
	getters: {
		existService (state) {
			return state.data !== null;
		}
	},
	actions: {
		resetService (context) {
			context.commit('setService', null);
		}
	},
};